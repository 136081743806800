import React, { Component } from "react";
import CONFIG from "../Config";
import { connect } from "react-redux";
import { getLanguages } from "../server.js";

import CustomSelect from "./CustomSelect";
import Logo from "../img/logo/small/logo.png";

import axios from "axios";

class Header extends Component {
  constructor (props) {
    super(props);

    this.state = {
      menu: [],
      show_login: false,
      isLoading: false,
      languages: [],
      isSelectOpen: false,
    };

    this.menu = [
      { href: CONFIG.ACTIVATION, name: "Activation" },
      { href: CONFIG.UPLOAD_PLAYLIST, name: "Upload playlist" },

    ];

    this.menuLoggedIn = [
      { href: CONFIG.DEVICE, name: "Devices" },
      { href: CONFIG.LOGOUT, name: "Logout" },
    ];
  }

  componentDidMount() {
    this.props.setLoading(true);
    if (this.props.loggedIn) {
      this.setState({ menu: this.menuLoggedIn, show_login: false });
    } else {
      this.setState({ menu: this.menu, show_login: true });
    }

    getLanguages(
      (data) => {
        if (!data.error) {
          this.setState({ languages: data.message });
          const token = localStorage.getItem("token");
          const languageId = localStorage.getItem("language_id") || 1;
          this.getTranslations(languageId, token);
          this.props.setLoading(false);
        }
      },
      (err) => {
        this.props.setLoading(false);
      }
    );
  }

  changeLanguageHandler = (id) => {
    this.props.setLoading(true);
    this.props.showSelectBar();
    const token = localStorage.getItem("token");
    this.getTranslations(id, token);

    localStorage.setItem("language_id", id);
  };

  getTranslations(id, token) {
    axios
      .get(CONFIG.API + CONFIG.ROUTES.TRANSLATION, {
        params: {
          id,
          token,
        },
      })
      .then((res) => {
        this.props.setLoading(false);
        this.props.changeLanguage(res.data.message);

        this.state.menu.forEach((item, index) => {
          this.setState({
            ...this.state.menu,
            [this.state.menu[0].name]:
              this.props.translations.state["activation"],
          });
        });
      })
      .catch((err) => {
        this.props.setLoading(false);
      });
  }

  toggleOutsideBar = () => {
    this.props.hideSelectWrapper();
  };

  openOutsideBar = () => {
    this.props.showSelectBar();
  };

  toggleSelectVisibility = (isVisible) => {
    this.setState({ isSelectOpen: isVisible });
  };

  render() {
    return (
      <header>
        <div className={"row_head"}>
          <div className={"col-lg-1"}>
            <img className={"logo"} src={Logo} alt="" />
            {this.props.loggedIn}
          </div>
          <div className={"col-lg-7"}>
            <ul>
              {this.state.menu.map((index, key) => {
                return (
                  <li key={key}>
                    <a
                      onClick={() => {
                        if (index.href.indexOf("logout") !== -1) {
                          window.location.href = index.href;
                          window.location.reload();
                        } else if (index.href.indexOf("devices") !== -1) {
                          window.location.href = index.href;
                          window.location.reload();
                        } else {
                          window.location.href = index.href;
                        }
                      }}
                    >
                      {index.name}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className={"col-lg-4"}>
            {this.state.show_login ? (
              <ul>
                <li>
                  <a href={CONFIG.LOGIN}>Login</a>
                </li>
                <li className="seperator">/</li>
                <li>
                  <a href={CONFIG.REGISTRATION}>Register</a>
                </li>

                <li>
                  {this.state.languages && this.state.languages.length && (
                    <CustomSelect
                      languages={this.state.languages}
                      isOpen={this.state.isSelectOpen}
                      toggleSelectVisibility={this.toggleSelectVisibility}
                      changeLanguageHandler={this.changeLanguageHandler}
                      openOutsideBar={this.openOutsideBar}
                    />
                  )}
                </li>
              </ul>
            ) : (
              ""
            )}
          </div>
        </div>
      </header>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    translations: state.translations,
    isOpen: state.global.isOpen,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeLanguage: (language) => {
      dispatch({ type: "CHANGE_LANGUAGE", payload: language });
    },

    setLoading: (loading) => {
      dispatch({ type: "SET_LOADING", payload: loading });
    },

    hideSelectWrapper: () => {
      dispatch({ type: "HIDE_SELECT_WRRAPER" });
    },

    showSelectBar: () => {
      dispatch({ type: "SHOW_SELECT_WRRAPER" });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);

import React from 'react';
import Header from './Header'
import Footer from './Footer'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from "react-toastify";
import { validateToken } from "../server";
import CookieAccept from "./CookieAccept";

class AccountBody extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            page: <div></div>,
        }

        this.form = "";

    }

    componentDidMount() {

        validateToken(() => {
            this.setState({
                page: <div>
                    <ToastContainer />
                    <CookieAccept />
                    <Header loggedIn={true} />
                    <div className={"container"}>{this.props.children}</div>
                    <Footer />
                </div>
            })

        }, () => {
            window.location.href = "#/login"
        })
    }

    render() {
        return (
            <div>
                {this.state.page}
            </div>
        );
    }
}

export default AccountBody;

import React, { Component } from 'react';
import Header from './Header'
import Footer from './Footer'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from "react-toastify";
import CookieAccept from "./CookieAccept";

class Body extends Component {

    constructor (props) {
        super(props);
    }

    getQuery() {

        let params = window.location.href.split("?")[1];

        var query = {};

        if (params) {

            params = params.split("&");

            for (var i = 0; i < params.length; i++) {
                var param = params[i].split("=");

                query[param[0]] = param[1];
            }
        }

        return query;

    }

    componentDidMount() {

        let query = this.getQuery();

        console.log("start", query);

        if (query.token) localStorage.setItem("token", query.token);

    }

    render() {
        let header = <Header />;
        return (
            <div>
                <CookieAccept />
                <ToastContainer />
                {header}
                <div className={"block"}>
                    {this.props.children}
                </div>
                <Footer />
            </div>
        );
    }
}

export default Body;

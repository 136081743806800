import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import { connect } from "react-redux";
import lg from "../img/webos.webp";
import samsung from "../img/tizen.png";

import {
  activateReferralDevice,
  getAppInfo,
  paypalPayment,
  validateMacAddress,
  voucherCodeActivation,
} from "../server";

import Loading from "./Loading";
import { toast } from "react-toastify";
import PopUp from "./PopUp/PopUp";
import BgImage from '../img/iptv.jpeg';

class Payment extends Component {
  constructor (props) {
    super(props);
    this.state = {
      email: "",
      price: 0,
      macIsValid: false,
      isLoading: true,
      mac: "",
      isAllChecked: false,
      isPrivacyAccepted: false,
      isTermsAccepted: false,
      isSalesAccepted: false,
      payWithPeyPall: true,
      voucher_code: "",
      voucher_code_activation_popup: false,
      voucher_mac: "",
      payment_method: "paypal",
      activeType: "yearly",
      validEmail: true
    };

    this.codeValidator = new SimpleReactValidator();
    this.onClose = this.onClose.bind(this);
  }


  hideForceLoading () {
    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 2000);
  }
  activate_by_voucher_code () {
    //A751839

    this.setState({
      loading: true,
    });

    voucherCodeActivation(
      this.state.voucher_code,
      this.state.voucher_mac,
      (data) => {
        toast.success("Device activated with success");
        this.setState({
          loading: false,
          voucher_code_activation_popup: false,
        });
      }
    );

    this.hideForceLoading();
  }

  activateReferral (cb) {
    let referral = this.getReferral();
    if (referral.code && referral.reseller_id) {
      activateReferralDevice(
        referral.code,
        this.state.mac,
        referral.reseller_id,
        (data) => {
          cb();
        }
      );
    } else {
      cb();
    }
  }

  handlePayment (details) {

    let payment_id = details.purchase_units[0].payments.captures[0].id;

    this.activateReferral(() => {

      let { mac, activeType, email, priceYearly, priceLifeTime } = this.state;

      let price = activeType === "yearly" ? priceYearly : priceLifeTime;

      let data = {
        mac,
        price,
        payment_id,
        email,
        detiles: JSON.stringify(details),
        type: activeType,
      }

      paypalPayment(data,
        () => {
          window.location.href = "#/ok";
          this.setState({ isLoading: false });
        },
        () => {
          window.location.href = "#/fail";
          this.setState({ isLoading: false });
        }
      );

    });

  }

  handleStripePayment (info) {

    let payment_id = info.paymentIntent.id;

    let { mac, activeType, email, priceYearly, priceLifeTime } = this.state;

    let price = activeType === "yearly" ? priceYearly : priceLifeTime;

    let detiles = JSON.stringify(info);

    this.activateReferral(() => {

      let data = {
        mac,
        price,
        payment_id,
        email,
        detiles,
        type: activeType,
      }

      paypalPayment(data,
        () => {
          window.location.href = "#/ok";
          this.setState({ isLoading: false });
        },
        () => {
          window.location.href = "#/fail";
          this.setState({ isLoading: false });
        }
      );

    });

  }


  getReferral () {
    return this.props.location.query;
  }

  handleStripe () {

    // if (document.getElementById("payment-element")) {

    //   get_publishing_key((pub_key) => {

    //     this.stripe = window.Stripe(pub_key.message);

    //     get_client_secret(this.state.activeType, (secret_key) => {

    //       this.client_secret = secret_key.message;

    //       this.elements = this.stripe.elements({
    //         clientSecret: secret_key.message,
    //       });

    //       if (document.getElementById("payment-element")) {

    //         let paymentElement = this.elements.create("payment");
    //         paymentElement.mount("#payment-element");

    //       }

    //     });

    //   });

    // }

  }

  initPaypalButtons () {
    document.getElementById("buttons-paypal").innerHTML = "";
    window.paypal
      .Buttons({
        createOrder: (data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  value: this.state.activeType === "yearly" ? this.state.priceYearly : this.state.priceLifeTime,
                },
                description: this.state.mac,
              },
            ],
          });
        },
        onApprove: (data, actions) => {
          return actions.order.capture()
            .then((details) => {
              this.handlePayment(details);
            });
        },
        onError: function (err) {
          this.setState({ isLoading: false });
          toast.error(err.message);
        },
      })
      .render("#buttons-paypal");
  }
  componentDidMount () {
    this.setTab("paypal");
    getAppInfo((data) => {
      this.setState(
        {
          priceLifeTime: data.message.price_lifetime,
          priceYearly: data.message.price_yearly,
        },
        () => {
          this.handleStripe();
          this.initPaypalButtons();
          this.setState({ isLoading: false });
        }
      )
    });

    let mac = this.props.location.query.mac;
    if (mac && mac.length > 0) {
      this.setState({ mac: mac, macIsValid: true });
    }

    let email = window.localStorage.getItem("email");
    if (email && email.length) {
      this.setState({
        email: email,
      });
    }
  }

  async submitStripeForm () {
    this.setState({ isLoading: true });
    try {
      let data = await this.stripe.confirmPayment({
        elements: this.elements,
        redirect: "if_required",
        confirmParams: {
          return_url: this.stripe_redirect_url_success,
          payment_method_data: {
            billing_details: {
              name: this.state.mac,
              email: this.state.email || this.state.mac + "@noemail.com",
            }
          },
        },
      });
      if (data.error) {
        toast.error(data.error.message);
      } else {
        this.handleStripePayment(data);
      }
      this.setState({ isLoading: false });
    } catch (e) {
      this.setState({ isLoading: false });

      toast.error(e.message);
    }
  }


  formValid () {
    if (this.codeValidator.allValid()) {
      return true;
    } else {
      this.codeValidator.showMessages();
      this.forceUpdate();
      return false;
    }
  }


  setTab (tab) {
    this.setState({
      payment_method: tab,
    });
    if (tab === "stripe") {
      document.getElementById("buttons-paypal").style.display = "none";
      document.getElementById("payment-from").style.display = "block";
      // document.getElementById("stripe-btn").classList.add("active-tab");
      // document.getElementById("paypal-btn").classList.remove("active-tab");
    }

    if (tab === "paypal") {
      document.getElementById("buttons-paypal").style.display = "block";
      document.getElementById("payment-from").style.display = "none";
      // document.getElementById("stripe-btn").classList.remove("active-tab");
      // document.getElementById("paypal-btn").classList.add("active-tab");
    }
  }

  onClose () {
    this.setState({
      voucher_code_activation_popup: false,
    });
  }

  onClickPaymentType (type) {

    this.setState({ activeType: type }, () => {
      this.handleStripe();
      this.initPaypalButtons();
    });

  }


  render () {

    let displayButtons = "none";
    let loading = "";
    if (this.state.isLoading || this.props.translations.isLoading)
      loading = <Loading />;
    if (
      this.state.isPrivacyAccepted &&
      this.state.isTermsAccepted &&
      this.state.isSalesAccepted &&
      this.state.macIsValid === true
    ) {
      displayButtons = "block";
    }

    const {
      enter_r_email,
      enter_email_add,
      enter_mac,
      mac_add_not_valid,
      agree_all,
      privacy_policy,
      terms_of_use,
      terms_of_sales,
      you_must_agree,
      active,
      the_activation_price,
      agree_with_our,
      pay_once_and,
      we_dont_provide,
      activate_by_voucher,
      activation_title,
      activation_text,
      activation_important_text,
      supported_device,
      only_for_development

    } = this.props.translations.state;

    return (
      <div className={ "row" }>
        <PopUp
          onClose={ this.onClose }
          body={
            <div>
              <h1>Voucher code activation for Samsung and Lg Qa team</h1>
              <div className={ "form-group" }>
                <label htmlFor="username">Enter your mac</label>
                <input
                  value={ this.state.voucher_mac }
                  onChange={ (event) => {
                    this.setState({ voucher_mac: event.target.value });
                  } }
                  type="text"
                  id="code"
                  className={ "form-control" }
                />
              </div>
              <div className={ "form-group" }>
                <label htmlFor="username">Enter voucher code</label>
                <input
                  value={ this.state.voucher_code }
                  onChange={ (event) => {
                    this.setState({ voucher_code: event.target.value });
                  } }
                  type="text"
                  id="code"
                  className={ "form-control" }
                />
              </div>
              <div className={ "form-group" }>
                <div className={ "gravity-right" }>
                  <button
                    onClick={ () => this.activate_by_voucher_code() }
                    className={ "btn btn-success" }
                  >
                    Activate
                  </button>
                </div>
              </div>
            </div>
          }
          show={ this.state.voucher_code_activation_popup }
        />
        { loading }
        <div className={ "col-lg-4 left-block" }>
          <div className="payment-types">
            <div
              onClick={ () => this.onClickPaymentType("yearly") }
              className={ `payment-type-button ${this.state.activeType === "yearly" ? "active" : ""
                }` }
            >
              <p className="package-text">Yearly</p>

              <b style={ { color: "orange" } }>{ this.state.priceYearly }</b> EUR

            </div>

            <div
              onClick={ () => this.onClickPaymentType("lifetime") }
              className={ `payment-type-button ${this.state.activeType === "lifetime" ? "active" : ""
                }` }
            >
              <p className="package-text">One time</p>
              <b style={ { color: "orange" } }>{ this.state.priceLifeTime }</b> EUR
            </div>
          </div>
          <div className={ "form-group" }>
            <label id={ "email" } htmlFor="card-number">
              { enter_r_email }
            </label>
            <span className={ "error-message" }>
              { this.codeValidator.message("email", this.state.email, "required|email") }
              { !this.state.validEmail && this.state.email !== "" && "please enter valid email address" }
            </span>
            <input
              onChange={ (event) => {

                let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
                if (event.target.value.match(mailformat)) {
                  this.setState({ validEmail: true });

                } else {
                  this.setState({ validEmail: false });
                }

                this.setState({ email: event.target.value });
              } }
              value={ this.state.email }
              placeholder={ "ex@domain.tld" }
              type="text"
              id={ "email" }
              className={ "form-control" }
            />
          </div>
          <div className={ "form-group" }>
            <label id={ "mac" } htmlFor="card-number">
              {/* Enter your mac address */ }
              { enter_mac }
            </label>
            <span className={ "error-message" }>
              { this.codeValidator.message("mac", this.state.mac, "required") }
            </span>
            <input
              onChange={ (event) => {
                this.setState({
                  mac: event.target.value,
                });
                validateMacAddress(
                  event.target.value,
                  (isValid) => {
                    console.log(isValid);
                    this.setState({ macIsValid: true });
                  },
                  () => {
                    this.setState({ macIsValid: false });
                  }
                );
              } }
              value={ this.state.mac }
              placeholder={ "CC-CC-CC-CC" }
              type="text"
              id={ "mac" }
              className={ "form-control" }
            />

            {
              this.state.macIsValid === false && this.state.mac !== "" ?
                <span style={ { color: "red" } }>
                  { mac_add_not_valid }
                </span> :
                this.state.macIsValid === true && this.state.mac !== "" &&
                <span style={ { color: "green" } }>
                  Mac address is valid
                </span>
            }

          </div>
          <div className={ "row" }>
            <div className={ "col-md-6" }>
              <div className={ "form-group" }>
                <div style={ { display: "block" } }>

                  <input
                    id={ "all" }
                    type="checkbox"
                    onClick={ () => {
                      this.setState({
                        isAllChecked: !this.state.isAllChecked,
                        isPrivacyAccepted: !this.state.isAllChecked,
                        isTermsAccepted: !this.state.isAllChecked,
                        isSalesAccepted: !this.state.isAllChecked,
                      });
                    } }
                    checked={ this.state.isAllChecked }
                    value={ this.state.isAllChecked }
                  />

                  <a
                    style={ {
                      display: "inline-block",
                      marginLeft: "10px",
                      fontSize: "21px",
                    } }
                  >
                    <label htmlFor="all">{ agree_all }</label>
                  </a>
                </div>
                <div>
                  <input
                    type="checkbox"
                    onClick={ () => {
                      this.setState({
                        isPrivacyAccepted: !this.state.isPrivacyAccepted,
                      });
                    } }
                    checked={ this.state.isPrivacyAccepted }
                    value={ this.state.isPrivacyAccepted }
                  />
                  <a
                    style={ {
                      display: "inline-block",
                      marginLeft: "10px",
                      color: "white"
                    } }
                    href="#/privacy"
                  >
                    { privacy_policy }
                  </a>
                </div>
                <div>
                  <input
                    type="checkbox"
                    onClick={ () => {
                      this.setState({
                        isTermsAccepted: !this.state.isTermsAccepted,
                      });
                    } }
                    checked={ this.state.isTermsAccepted }
                    value={ this.state.isTermsAccepted }
                  />
                  <a
                    style={ {
                      display: "inline-block",
                      marginLeft: "10px",
                      color: "white"
                    } }
                    href="#/termsofuse"
                  >
                    { terms_of_use }
                  </a>
                </div>
                <div>
                  <input
                    type="checkbox"
                    onClick={ () => {
                      this.setState({
                        isSalesAccepted: !this.state.isSalesAccepted,
                      });
                    } }
                    checked={ this.state.isSalesAccepted }
                    value={ this.state.isSalesAccepted }
                  />
                  <a
                    style={ {
                      display: "inline-block",
                      marginLeft: "10px",
                      color: "white"
                    } }
                    href="#/termsofsales"
                  >
                    { terms_of_sales }
                  </a>
                </div>
              </div>
            </div>
            <div className={ "col-md-6" }></div>
          </div>


          <div>
            <div style={ { display: displayButtons } }>
              {/* <button
                id="paypal-btn"
                onClick={ () => this.setTab("paypal") }
                className="tab-button active-tab"
              >
                PAYPAL
              </button>
              <button
                id="stripe-btn"
                onClick={ () => this.setTab("stripe") }
                className="tab-button"
              >
                STRIPE
              </button> */}
              <div className="tab-body">
                <div id="buttons-paypal"></div>
                <div id="payment-from">
                  <div id="payment-element"></div>
                  <button
                    onClick={ () => this.submitStripeForm() }
                    style={ { width: "100%", marginTop: "20px" } }
                    className={ "btn btn-success" }
                    id="submit"
                  >
                    Pay { this.state.activeType == "yearly" ? this.state.priceYearly : this.state.priceLifeTime } EUR
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="activation-support">
            <p style={ { textAlign: "center" } } >
              <h1>{ only_for_development }</h1>
              { activate_by_voucher }

              <button
                style={ { display: "block", margin: "10px auto" } }
                onClick={ () => {
                  this.setState({
                    voucher_code_activation_popup: true,
                  });
                } }
                className={ "btn btn-success" }
              >
                { active }
              </button>
            </p>
          </div>
        </div>
        <div style={ {
          padding: "5%",
          backgroundImage: "url('" + BgImage + "')",
          backgroundSize: "cover"
        } } className={ "col-lg-8 aside-block" }>
          <div class="bg-mask"></div>
          <div className={ "c-block" }>
            <div style={ { color: "white" } }>
              <h1 style={ { color: "white" } }>{ activation_title }</h1> { activation_text }
              <br />

              <hr />
              { activation_important_text }
            </div>
            <br />
            <h3 style={ { color: "white" } }>{ pay_once_and }</h3>
            <ol style={ { color: "white" } }>
              <li>{ enter_email_add }</li>
              <li>{ enter_mac }</li>
              <li>{ agree_with_our }</li>
              <li>{ this.props.translations.state["hit pay and join"] }</li>
            </ol>

            <h1 style={ { color: "white" } }>{ supported_device }</h1>
            <div className="devices">
              <div className={ "row" }>
                <div className={ "col-md-4" }>
                  <img src={ lg } alt={ "LG" } />
                  <p><b>LG WebOS</b></p>
                </div>
                <div className={ "col-md-4" }>
                  <img src={ samsung } alt={ "Samsung" } />
                  <p>
                    <b>Samsung Tizen</b>
                  </p>
                </div>
              </div>
            </div>
          </div>

        </div>


      </div >
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.isLoading,
    translations: state.translations,
  };
};

export default connect(mapStateToProps)(Payment);

const CONFIG = {
  APP_NAME: "Nordic IPTV",
  SUPPORT_EMAIL: "support@niptv.eu",
  DOMAIN: "https://niptv.eu",
  SALE_EMAIL: "support@niptv.eu",

  API: "https://api.niptv.eu/api",
  RESSELER_API: "https://api.niptv.eu",
  BECOME_RESELLER: "https://reseller.niptv.eu",
  RESELLER_PANEL: "https://reseller.niptv.eu",
  API_STRIPE: "https://api.niptv.eu/stripe",

  UPLOAD_PLAYLIST: "#/playlist_by_mac",
  ACTIVATION: "#/payment",
  DEVICE: "#/account/devices",
  LOGOUT: "#/account/logout",
  HELP: "#/help",
  LOGIN: "#/login",
  REGISTRATION: "#/registration",
  PRIVACY: "#/privacy",
  TERMS_OF_USE: "#/termsofuse",
  TERMS_OF_SALES: "#/termsofsales",

  ROUTES: {
    PARSE_PLAYLIST: "/parse_playlist",
    LOGIN_MAC: "/login_by_mac",
    CONFIRM_LOGIN: "/confirm_login_by_mac",
    LOGIN_EMAIL: "/login",
    MAKE_PAYMENT: "/payment",
    DEVICE: "/devices",
    DEVICE_ADD: "/device",
    CONFIRM_DEVICE: "/confirm_device",
    ADD_PLAYLIST: "/playlist",
    REGISTER: "/register",
    INFO: "/app_info",
    PAY: "/payment",
    PARSE: "/parse_playlist",
    VALIDATE: "/validate",
    PAYPAL: "/paypal/payment",
    IP: "/getIp",
    VOUCHER_CODE: "/voucher_code_activation",
    VALIDATE_MAC: "/validate_mac",
    RESSELLER: "/resseler/register",
    LANGUAGE: "/languages",
    TRANSLATION: "/translations",
    RESSELLER_CONFIRM: "/resseler/check_code",
    REFFERAL_ACTIVATION: "/resseler/activate_by_link",

    PUBLISHING_KEY: "/publishing_key",
    SECRET: "/secret",

  },
};

export default CONFIG;

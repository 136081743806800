import Loading from "./Loading";
import React from 'react'
class Logout extends React.Component{
    constructor(props) {
        super(props);
    }

    componentDidMount(){
        window.localStorage.clear();
        window.location.href = "#/login"
    }

    render() {
        return (
            <div>
                <Loading/>
            </div>
        );
    }

}

export default Logout;
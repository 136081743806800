import React,{Component} from 'react';
class OK extends Component{

    render() {
        return (
            <div style={{textAlign:"center"}}>
                <h1>PAYMENT DONE WITH SUCCESS</h1>
            </div>
        );
    }
}

export default OK;
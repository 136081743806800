import React,{Component} from 'react';

class CookieAccept extends Component{
    constructor() {
        super();
        this.state= {
            show:false
        }
    }
    componentDidMount() {
        let isCookieAccepted = window.localStorage.getItem("cookie");
        if (!isCookieAccepted){
            this.setState({
                show:true
            })
        }
    }

    render() {
        let cookie = "";
        if (this.state.show){
            cookie = <div className={"banner-cookie"}>
                <p>This website uses cookies.By using this website you consent to our use of these cookies.For more information visit our
                    <a href="#/privacy"> Privacy Policy </a> <button onClick={()=>{
                            window.localStorage.setItem("cookie",1);
                            this.setState({show:false})
                    }} className={"btn btn-success"}>Got it!</button> </p>
            </div>
        }
        return(
            <div>{cookie}</div>
        );

    }
}

export default CookieAccept;
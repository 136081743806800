import React, {Component} from 'react';
import devices  from '../data/devices.json'
import {parsePlaylist} from "../server";
import Loading from "./Loading";

class ManagePlatlists extends Component{

    constructor(props) {
        super(props);
        this.state = {
            list:[],
            types  : [],
            categoryIndex:0,
            selectedTypeIndex:0,
        }
    }
    componentDidMount() {
        let query = this.props.location.query;
        parsePlaylist(query.id,(data)=>{
            data = data.message;
            for (let key in data) this.state.types.push(key.toUpperCase());
            this.setState({types:this.state.types,list:data})
        });
    }

    setType(key){this.setState({ selectedTypeIndex:key,categoryIndex:0})}
    changeTvCategory(key){
        this.setState({categoryIndex:key},()=>{
            document.getElementById("scrollBlock").scrollTop = 0;

        })
    }

    printGroups(){
        return <div className={"col-md-4"} style={{overflow: "scroll", height: "500px", overflowX: "hidden"}}>
            {this.state.list[this.state.types[this.state.selectedTypeIndex].toLowerCase()].groups ?
                this.state.list[this.state.types[this.state.selectedTypeIndex].toLowerCase()].groups.map((index,key)=>{
                    if (this.state.categoryIndex === key){
                        return <div key={key} className={"row active category-item"}>
                            <div className={"col-md-7"}>
                                {index}
                            </div>
                        </div>
                    }else{
                        return <div onClick={()=>this.changeTvCategory(key)} className={"row category-item"}>
                            <div className={"col-md-7"}>
                                {index}
                            </div>
                        </div>
                    }
                })
                : "No groups"}
        </div>
    }
    printChannels(){
        let data = this.state.list[this.state.types[this.state.selectedTypeIndex].toLowerCase()].data;
        let newList = [];
        let groupName = this.state.list[this.state.types[this.state.selectedTypeIndex].toLowerCase()].groups[this.state.categoryIndex];
        for (let i = 0; i < data.length;i++){
            if (groupName  === data[i].group){newList.push(data[i]);}
        }
        return (
            <div className={"row"}>
                {this.printGroups()}
                  <div className={"col-md-8"} id={"scrollBlock"}  style={{overflow: "scroll", height: "500px", overflowX: "hidden"}}>
                      {newList.length ?
                          newList.map((index,key)=>{
                              return <div key={key} className={"row channel-item"}>
                                        <div className={"col-md-2"}><img src={index.logo}  alt=""/></div>
                                        <div className={"col-md-7"}>{index.name}</div>
                              </div>
                          })
                          : "No channels"
                      }
                  </div>
            </div>
        )
    }
    changeMovieCategory(key){
        this.setState({ selectedMovieCategory:key })
    }

    printMovies(){
        let data = this.state.list[this.state.types[this.state.selectedTypeIndex].toLowerCase()].data;
        let newList = [];
        let groupName = this.state.list[this.state.types[this.state.selectedTypeIndex].toLowerCase()].groups[this.state.categoryIndex];
        for (let i = 0; i < data.length;i++){
            if (groupName  === data[i].group){newList.push(data[i]);}
        }
        return (
            <div className={"row"}>
                {this.printGroups()}
                <div className={"col-md-8"} id={"scrollBlock"}  style={{overflow: "scroll", height: "500px", overflowX: "hidden"}}>
                    {newList.length ?
                        newList.map((index,key)=>{
                            return <div key={key} className={"row channel-item"}>
                                <div className={"col-md-2"}><img src={index.logo}  alt=""/></div>
                                <div className={"col-md-7"}>{index.name}</div>
                            </div>
                        })
                        : "No channels"
                    }
                </div>
            </div>
        )
    }
    printSeries(){
        let data = this.state.list[this.state.types[this.state.selectedTypeIndex].toLowerCase()].data;
        let newList = [];
        let groupName = this.state.list[this.state.types[this.state.selectedTypeIndex].toLowerCase()].groups[this.state.categoryIndex];
        for (let i = 0; i < data.length;i++){
            if (groupName  === data[i].group){newList.push(data[i]);}
        }
        return(
            <div className={"row"}>
                {this.printGroups()}
                <div className={"col-md-8"} id={"scrollBlock"}  style={{overflow: "scroll", height: "500px", overflowX: "hidden"}}>
                    {newList.length ?
                        newList.map((index,key)=>{
                            return <div key={key} className={"row channel-item"}>
                                <div className={"col-md-2"}><img src={index.logo}  alt=""/></div>
                                <div className={"col-md-7"}>{index.name}</div>
                                <div className={"col-md-3"}> Seasons {Object.keys(index.seasons).length}</div>
                            </div>
                        })
                        : "No channels"
                    }
                </div>
            </div>
        )

    }
    render(){
        let renderedBlock = "";
        if (Object.keys(this.state.list).length > 0){
            if (this.state.selectedTypeIndex === 0){
                renderedBlock = this.printChannels()
            }else if (this.state.selectedTypeIndex === 1){
                renderedBlock = this.printMovies();
            }else{
                renderedBlock = this.printSeries();
            }
        }else{
            renderedBlock = <Loading/>
        }

        return(
            <div>
                <h1 className={"heading"}>Device {this.props.location.query.name}</h1>
                <div className={"type"}>
                    <ul>
                    {this.state.types.map((index,key)=>{
                        if (this.state.selectedTypeIndex === key){
                            return <li className={"types active-type"} key={key} onClick={()=>this.setType(key)}>{index}</li>
                        }else{
                            return <li className={"types"}  key={key} onClick={()=>this.setType(key)}>{index}</li>
                        }
                    })}
                    </ul>
                </div>


                {renderedBlock}

            </div>
        );
    }
}

export default ManagePlatlists;

import React from 'react';
class Loading extends React.Component{
    constructor(props) {
        super(props);
    }
    render(){
        return(
            <div className={"parent-loading"}>
                <div className={"loader"}/>
            </div>

        )
    }
}

export default Loading;
import React, { Component } from "react";

class AutoAdd extends Component {

    componentDidMount () {

        let mac = this.props.location.query.mac;
        let device = this.props.location.query.device;
        let token = this.props.location.query.token;

        if (token) localStorage.setItem("token", token);

        if (token && mac && device) {
            window.location.href = "#/account/addpllaylist?mac=" + mac + "&device=" + device + "&auth=mac"
        } else {
            window.location.href = "#/login";
        }

    }

    render () {
        return (
            <div>
                loading...
            </div>
        )
    }
}

export default AutoAdd;
import { combineReducers } from "redux";
import messages from "./messages";
import translations from "./translations";
import global from "./global";
import { routerReducer } from "react-router-redux";

export default combineReducers({
  routing: routerReducer,
  messages,
  global,
  translations,
});

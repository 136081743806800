import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import PopUp from "./PopUp/PopUp";
import Loading from "./Loading";
import { ConfirmResellerCode, recaptcha, RegisterReseller } from "../server";
import { toast, ToastContainer } from "react-toastify";
import country from "../country.json";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { connect } from "react-redux";
import BgImage from '../img/iptv.jpeg';

class Reseller extends Component {
  constructor (props) {
    super(props);
    this.state = {
      id: 0,
      registration: {
        name: "",
        surname: "",
        email: "",
        phone: "",
        password: "",
        country: "",
        company: "",
        address: "",
        is_resseler: true,
        is_refferal: false,
        is_privacy_accepted: false,
        is_usage_accepted: false,
        validEmail: true

      },
      search: [],
      code: "",
      show: false,
      isLoading: false,
    };

    this.validator = new SimpleReactValidator();
    this.onClose = this.onClose.bind(this);
  }
  componentDidMount() {
  }

  searchCountry(toSearch) {
    let results = [];

    for (let i = 0; i < country.length; i++) {
      for (let key in country[i]) {
        if (country[i][key].indexOf(toSearch) != -1) {
          results.push(country[i]);
        }
      }
    }

    this.setState({
      search: results,
    });
  }

  hideForceLoading() {
    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 2000);
  }

  onClose() {
    this.setState({
      show: false,
    });
  }
  formValid(validator, filedName) {
    if (validator.fieldValid(filedName)) {
      return true;
    } else {
      validator.showMessages();
      this.forceUpdate();
      return false;
    }
  }

  confirmCode() {
    let code = this.state.code;

    if (!code) {
      toast.error("Code required");
      return;
    }

    this.setState({
      loading: true,
    });
    ConfirmResellerCode(this.state.code, this.state.id, (data) => {
      toast.success("Confirmation success");
      this.setState({
        loading: false,
        show: false,
      });

      window.location.href = "https://reseller.niptv.eu";
    });
  }

  register() {
    if (
      this.formValid(this.validator, "email") &&
      this.formValid(this.validator, "name") &&
      this.formValid(this.validator, "password") &&
      this.formValid(this.validator, "surname") &&
      this.formValid(this.validator, "phone")
    ) {
      let that = this;
      RegisterReseller(
        {
          ...that.state.registration,
        },
        (data) => {
          this.setState({ id: data.message.id, loading: false, show: true });
        }
      );

      that.hideForceLoading();
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  render() {
    let loading = "";
    if (this.state.isLoading || this.props.translations.isLoading) {
      loading = <Loading />;
    }

    const {
      become_partner,
      enter_company_name,
      entr_r_surname,
      select_r_country,
      enter_r_add,
      enter_r_email,
      enter_r_phone,
      enter_r_pass,
      choose_partner_type,
      reseller,
      referral,
      accept_privacy_policy_usage,
      privacy_policy,
      terms_of_usage,
      register,
      lets_work_together,
      partnership_goals,
      find_right_fit,
      reseller_program,
      affiliate_program,
      flexible_program_u_want,
    } = this.props.translations.state;
    return (
      <div className={"row"}>
        <ToastContainer />
        {loading}
        <PopUp
          onClose={this.onClose}
          body={
            <div>
              <h1>Enter code</h1>
              <p>The code sent to your email address </p>
              <div className={"form-group"}>
                <label htmlFor="username">Enter your code</label>
                <span className={"error-message"}>
                  {this.validator.message("code", this.state.code, "required")}
                </span>
                <input
                  value={this.state.code}
                  onChange={(event) => {
                    this.setState({ code: event.target.value });
                  }}
                  type="text"
                  id="code"
                  className={"form-control"}
                />
              </div>
              <div className={"form-group"}>
                <div className={"gravity-right"}>
                  <button
                    onClick={() => this.confirmCode()}
                    className={"btn btn-success"}
                  >
                    Confirm code
                  </button>
                </div>
              </div>
            </div>
          }
          show={this.state.show}
        />

        <div className={"col-lg-4 left-block"}>
          <h3 className={"heading"}>{become_partner}</h3>
          <div className={"form-group"}>
            <label htmlFor="username">{enter_company_name}</label>
            <span className={"error-message"}>
              {this.validator.message(
                "company",
                this.state.registration.company,
                "required"
              )}
            </span>
            <input
              autoComplete={"nope"}
              onChange={(event) => {
                this.state.registration.company = event.target.value;
                this.setState({ registration: this.state.registration });
              }}
              value={this.state.registration.company}
              type="text"
              id={"Company"}
              className={"form-control"}
            />
          </div>

          <div className={"form-group"}>
            <label htmlFor="username">
              Enter your name <span style={{ color: "red" }}>*</span>
            </label>
            <span className={"error-message"}>
              {this.validator.message(
                "name",
                this.state.registration.name,
                "required"
              )}
            </span>
            <input
              autoComplete={"nope"}
              onChange={(event) => {
                this.state.registration.name = event.target.value;
                this.setState({ registration: this.state.registration });
              }}
              value={this.state.registration.name}
              type="text"
              id={"Name"}
              className={"form-control"}
            />
          </div>
          <div className={"form-group"}>
            <label htmlFor="username">
              {entr_r_surname} <span style={{ color: "red" }}>*</span>
            </label>
            <span className={"error-message"}>
              {this.validator.message(
                "surname",
                this.state.registration.surname,
                "required"
              )}
            </span>
            <input
              autoComplete={"nope"}
              onChange={(event) => {
                this.state.registration.surname = event.target.value;
                this.setState({ registration: this.state.registration });
              }}
              value={this.state.registration.surname}
              type="text"
              id={"Surname"}
              className={"form-control"}
            />
          </div>

          <div className={"form-group"}>
            <label htmlFor="username">{select_r_country}</label>
            <span className={"error-message"}>
              {this.validator.message(
                "country",
                this.state.registration.country,
                "required"
              )}
            </span>
            <input
              autoComplete={"nope"}
              onInput={(event) => {
                let val = event.target.value;
                if (val.length > 0) {
                  this.searchCountry(val);
                }
              }}
              onChange={(event) => {
                this.state.registration.country = event.target.value;
                this.setState({ registration: this.state.registration });
              }}
              value={this.state.registration.country}
              type="text"
              id={"country"}
              className={"form-control"}
            />

            {this.state.search.length > 0 ? (
              <div
                style={{
                  width: "100%",
                  maxHeight: "200px",
                  background: "white",
                  height: "150px",
                  textAlign: "center",
                  overflow: "scroll",
                  overflowX: "hidden",
                  padding: "7px",
                }}
              >
                {this.state.search.map((index, key) => {
                  return (
                    <p
                      onClick={() => {
                        this.state.registration.country = index.name;
                        this.setState({
                          registration: this.state.registration,
                          search: [],
                        });
                      }}
                      className={"country_item"}
                      key={key}
                    >
                      {" "}
                      {index.code} {index.name}
                    </p>
                  );
                })}
              </div>
            ) : null}
          </div>
          <div className={"form-group"}>
            <label htmlFor="username">{enter_r_add}</label>
            <span className={"error-message"}>
              {this.validator.message(
                "country",
                this.state.registration.address,
                "required"
              )}
            </span>
            <input
              autoComplete={"nope"}
              onChange={(event) => {
                this.state.registration.address = event.target.value;
                this.setState({ registration: this.state.registration });
              }}
              value={this.state.registration.address}
              type="text"
              id={"address"}
              className={"form-control"}
            />
          </div>

          <div className={"form-group"}>
            <label htmlFor="username">
              {enter_r_email} <span style={{ color: "red" }}>*</span>
            </label>
            <span className={"error-message"}>
              {this.validator.message(
                "email",
                this.state.registration.email,
                "required|email"
              )}
              {!this.state.validEmail && this.state.registration.email !== "" && "please enter valid email address"}

            </span>
            <input
              autoComplete={"nope"}
              onChange={(event) => {
                this.state.registration.email = event.target.value;
                let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
                if (event.target.value.match(mailformat)) {
                  this.setState({ validEmail: true });

                } else {
                  this.setState({ validEmail: false });
                }

                this.setState({ registration: this.state.registration });
              }}
              value={this.state.registration.email}
              type="email"
              id={"Email"}
              className={"form-control"}
            />
          </div>

          <div className={"form-group"}>
            <label htmlFor="username">{enter_r_phone}</label>
            <span className={"error-message"}>
              {this.validator.message(
                "phone",
                this.state.registration.phone,
                "required"
              )}
            </span>
            <PhoneInput
              placeholder="Enter phone number"
              className={"form-control"}
              value={this.state.registration.phone}
              onChange={(data) => {
                this.state.registration.phone = data;
                this.setState({ registration: this.state.registration });
              }}
            />
            <input
              style={{ display: "none" }}
              autoComplete={"nope"}
              value={this.state.registration.phone}
              type="number"
              id={"Phone"}
              className={"form-control"}
            />
          </div>

          <div className={"form-group"}>
            <label htmlFor="username">
              {enter_r_pass} <span style={{ color: "red" }}>*</span>
            </label>
            <span className={"error-message"}>
              {this.validator.message(
                "password",
                this.state.registration.password,
                "required"
              )}
            </span>
            <input
              autoComplete={"nope"}
              onChange={(event) => {
                this.state.registration.password = event.target.value;
                this.setState({ registration: this.state.registration });
              }}
              value={this.state.registration.password}
              type="password"
              id={"Password"}
              className={"form-control"}
            />
          </div>

          <div className={"form-group"}>
            <label htmlFor="username">
              {choose_partner_type} <span style={{ color: "red" }}>*</span>
            </label>
            <div
              onClick={() => {
                this.state.registration.is_resseler =
                  !this.state.registration.is_resseler;
                this.setState({
                  registration: this.state.registration,
                });
              }}
            >
              <input
                type="checkbox"
                checked={this.state.registration.is_resseler}
              />
              <span style={{ color: "white" }}> {reseller}</span>
            </div>
            <div
              onClick={() => {
                this.state.registration.is_refferal = !this.state.registration.is_refferal;
                this.setState({
                  registration: this.state.registration,
                });
              }}
            >
              <input
                type="checkbox"
                checked={this.state.registration.is_refferal}
              />
              <span style={{ color: "white" }}> {referral}</span>
            </div>
          </div>

          <div className={"form-group"}>
            <label htmlFor="username">
              {accept_privacy_policy_usage}{" "}
              <span style={{ color: "red" }}>*</span>
            </label>
            <div
              onClick={() => {
                this.state.registration.is_privacy_accepted =
                  !this.state.registration.is_privacy_accepted;
                this.setState({
                  registration: this.state.registration,
                });
              }}
            >
              <input
                type="checkbox"
                checked={this.state.registration.is_privacy_accepted}
              />
              <span>
                {" "}
                <a href="#">{privacy_policy}</a>
              </span>
            </div>
            <div
              onClick={() => {
                this.state.registration.is_usage_accepted =
                  !this.state.registration.is_usage_accepted;
                this.setState({
                  registration: this.state.registration,
                });
              }}
            >
              <input
                type="checkbox"
                checked={this.state.registration.is_usage_accepted}
              />
              <span>
                {" "}
                <a href="#">{terms_of_usage}</a>
              </span>
            </div>
          </div>
          <div className={"form-group"}>
            <div className={"gravity-right"}>
              {this.state.registration.is_privacy_accepted &&
                this.state.registration.is_usage_accepted ? (
                <button
                  onClick={() => this.register()}
                  className={"btn btn-success"}
                >
                  {register}
                </button>
              ) : (
                <button className={"btn btn-success"} disabled={true}>
                  {register}
                </button>
              )}
            </div>
          </div>
        </div>
        <div
          style={{ backgroundImage: "url('" + BgImage + "')", backgroundSize: "cover" }}
          className={"col-lg-8 aside-block"}>
          <div class="bg-mask"></div>
          <div className="content-block">
            <h1> {lets_work_together}</h1>
            <p> {flexible_program_u_want}</p>

            <h2 style={{ color: "white" }}>{partnership_goals}</h2>
            <p>
              Joining our program means being part of our Team. We will take care
              of all your business needs in terms of support for the app as we
              fully understand that It takes more than a great product to grow
              your business—which means we rely on our partners just as much as
              they rely on us. Whether you’re looking to create new revenue
              opportunities with existing clients, acquire new customers by
              combining our app with your playlist sale for an all in one package,
              or all of the above, we’ve got the tools you need to make it happen.
            </p>

          </div>

        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.isLoading,
    translations: state.translations,
  };
};

export default connect(mapStateToProps)(Reseller);

import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import { registerUser } from "../server";
import Loading from "./Loading";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import BgImage from '../img/iptv.jpeg';

class Registration extends Component {
  constructor (props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      isLoading: false,
      register_succes: false,
      validEmail: true

    };
    this.validator = new SimpleReactValidator();
  }

  hideForceLoading() {
    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 2000);
  }
  formValid(validator, filedName) {
    if (validator.fieldValid(filedName)) {
      return true;
    } else {
      validator.showMessages();
      this.forceUpdate();
      return false;
    }
  }

  register() {
    if (
      this.formValid(this.validator, "email") &&
      this.formValid(this.validator, "password")
    ) {
      this.setState({ isLoading: true });
      registerUser(
        { email: this.state.email, password: this.state.password },
        (data) => {
          this.setState({
            isLoading: false,
            register_succes: true
          });


          if (this.state.register_succes) {
            toast.success(this.props.translations.state["you have successfully registered"])
            this.setState({
              register_succes: false
            })
          }


          window.localStorage.setItem("token", data.message);
          window.localStorage.setItem("email", this.state.email);
          if (!this.state.register_succes) {
            setTimeout(() => {
              window.location.href = "#/login"

            }, 1000)

          }
        }
      );
      this.hideForceLoading();
    }
  }

  render() {
    let loading = "";
    if (this.state.isLoading || this.props.translations.isLoading) {
      loading = <Loading />;
    }

    const {
      enter_r_email,
      create_for_managing,
      registration,
      enter_email_add,
      enter_r_pass,
      hit_register,
      // You have successfully registered
    } = this.props.translations.state;
    return (
      <div className={"row"}>
        {loading}
        <form
          className={"col-lg-4 left-block"}
          onSubmit={(e) => {
            e.preventDefault();
            this.register();
          }}
        >
          <h1>Register account</h1>
          <div className={"form-group"}>
            <label htmlFor="email">{enter_r_email}</label>
            <span className={"error-message"}>
              {this.validator.message(
                "email",
                this.state.email,
                "required|email"
              )}
              {!this.state.validEmail && this.state.email !== "" && "please enter valid email address"}

            </span>
            <input
              onChange={(event) => {

                let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
                if (event.target.value.match(mailformat)) {
                  this.setState({ validEmail: true });

                } else {
                  this.setState({ validEmail: false });
                }

                this.setState({
                  email: event.target.value,
                });
              }}
              value={this.state.email}
              type="text"
              id={"email"}
              className={"form-control"}
            />
          </div>
          <div className={"form-group"}>
            <label htmlFor="password">
              {" "}
              {this.props.translations.state["enter new password"]}
            </label>
            <span className={"error-message"}>
              {this.validator.message(
                "password",
                this.state.password,
                "required"
              )}
            </span>
            <input
              onChange={(event) => {
                this.setState({
                  password: event.target.value,
                });
              }}
              value={this.state.password}
              type="password"
              id={"password"}
              className={"form-control"}
            />
          </div>
          <div className={"form-group"}>
            <div className={"gravity-right"}>
              <button
                // onClick={() => this.register()}
                className={"btn btn-success"}
              >
                {registration}
              </button>
            </div>
          </div>
        </form>
        <div style={{ backgroundImage: "url('" + BgImage + "')", backgroundSize: "cover" }} className={"col-lg-8 aside-block"}>
          <div class="bg-mask"></div>
          <div className="content-block">
            <h1>{create_for_managing}</h1>
            <ol style={{ color: "white" }}>
              <li>{enter_email_add}</li>
              <li>{enter_r_pass}</li>
              <li>{hit_register}</li>
            </ol>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.isLoading,
    translations: state.translations,
  };
};

export default connect(mapStateToProps)(Registration);

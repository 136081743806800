import Axios from "axios";
import { toast } from "react-toastify";
import CONFIG from "./Config";
export const getToken = () => {
  return localStorage.getItem("token");
};
export const request = (url, body, callback, method, cberror, query, pc) => {
  if (url.indexOf("undefined") > -1) {
    return;
  }
  if (getToken()) body.token = getToken();

  let axios = null;
  const config = {
    onUploadProgress: function (progressEvent) {
      let percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      if (pc) {
        pc(percentCompleted);
      } else {
      }
    },
  };
  if (method === "delete") {
    axios = Axios[method](url + "?token=" + body.token, { data: body }, config);
  } else {
    let link = url + "?token=" + body.token;
    if (query) {
      link += "&mac=" + body.mac;
    }
    axios = Axios[method || "post"](link, body, config);
  }

  axios
    .then((data) => {
      if (data.data.error) {
        if (typeof data.data.message === "array") {
          for (let i = 0; i < data.data.message.length; i++) {
            toast.error(data.data.message[i].msg);
          }
        } else {
          if (typeof cberror === "function") {
            cberror(data.data.message);
          } else {
            toast.error(data.data.message);
          }
        }
      } else {
        callback(data.data);
      }
    })
    .catch((error) => {
      toast.error(error.message);
    });
};

export const loginByMac = (mac, cb) => {
  request(CONFIG.API + CONFIG.ROUTES.LOGIN_MAC, { mac }, cb);
};

export const validateMacAddress = (mac, cb, err) => {
  request(
    CONFIG.API + CONFIG.ROUTES.VALIDATE_MAC,
    { mac },
    cb,
    "get",
    err,
    true
  );
};

export const confirmLoginCode = (body, cb) => {
  request(CONFIG.API + CONFIG.ROUTES.CONFIRM_LOGIN, body, cb);
};

export const loginByEmail = (email, password, cb) => {
  request(CONFIG.API + CONFIG.ROUTES.LOGIN_EMAIL, { email, password }, cb);
};

export const getDevices = (cb) => {
  request(CONFIG.API + CONFIG.ROUTES.DEVICE, {}, cb, "get");
};

export const getDevice = (cb) => {
  request(CONFIG.API + "/device", {}, cb, "get");
};

export const addDevice = (mac, cb) => {
  request(CONFIG.API + CONFIG.ROUTES.DEVICE_ADD, { mac }, cb);
};

export const confirmDeviceCode = (body, cb) => {
  request(CONFIG.API + CONFIG.ROUTES.CONFIRM_DEVICE, body, cb);
};
export const addNewPlaylist = (body, cb) => {
  request(CONFIG.API + CONFIG.ROUTES.ADD_PLAYLIST, body, () => {
    toast.success("Playlist added");
    cb();
  });
};
export const addNewPlaylistMac = (body, cb) => {
  request(CONFIG.API + "/playlist_with_mac", body, () => {
    toast.success("Playlist added");
    cb();
  });
};
export const removePlaylist = (id, cb) => {
  request(CONFIG.API + CONFIG.ROUTES.ADD_PLAYLIST, { id }, cb, "delete");
};
export const registerUser = (body, cb) => {
  request(CONFIG.API + CONFIG.ROUTES.REGISTER, { ...body }, cb);
};

export const getAppInfo = (cb) => {
  request(CONFIG.API + CONFIG.ROUTES.INFO, {}, cb, "get");
};

export const payment = (body, cb) => {
  request(CONFIG.API + CONFIG.ROUTES.PAY, body, cb);
};

export const parsePlaylist = (id, cb) => {
  request(CONFIG.API + CONFIG.ROUTES.PARSE, { id }, cb);
};
export const validateToken = (cb, cberr) => {
  request(CONFIG.API + CONFIG.ROUTES.VALIDATE, {}, cb, null, cberr);
};

export const get_external_epg_list = (cb, err) => {
  request(CONFIG.API_V2 + "/epg_list", {}, cb, "get", err);
};

export const add_external_epg_list = (data, cb, err) => {
  request(CONFIG.API_V2 + "/epg_list", data, cb, "post", err);
};

export const remove_external_epg_link = (data, cb, err) => {
  request(CONFIG.API_V2 + "/epg_list", data, cb, "delete", err);
};

export const paypalPayment = (data, cb, cberr) => {
  request(CONFIG.API + CONFIG.ROUTES.PAYPAL, data, cb, null, cberr);
};

export const getIp = (cb, cberr) => {
  request(CONFIG.API + CONFIG.ROUTES.IP, {}, cb, "get", cberr);
};

export const getLanguages = (cb, err) => {
  request(CONFIG.API + CONFIG.ROUTES.LANGUAGE, {}, cb, "get", err);
};

export const RegisterReseller = (body, cb) => {
  request(
    CONFIG.RESSELER_API + CONFIG.ROUTES.RESSELLER,
    { ...body },
    cb,
    "post"
  );
};

export const ConfirmResellerCode = (code, id, cb) => {
  request(
    CONFIG.RESSELER_API + CONFIG.ROUTES.RESSELLER_CONFIRM,
    { code, id },
    cb,
    "post"
  );
};

export const voucherCodeActivation = (code, mac, cb) => {
  request(CONFIG.API + CONFIG.ROUTES.VOUCHER_CODE, { code, mac }, cb, "post");
};

export const activateReferralDevice = (code, mac, resseler_id, cb) => {
  request(
    CONFIG.RESSELER_API + CONFIG.ROUTES.REFFERAL_ACTIVATION,
    { code, mac, resseler_id },
    cb,
    "post"
  );
};

export const addPlaylistFromFile = (form, cb, pc) => {
  request(
    CONFIG.API + "/parse_playlist",
    form,
    cb,
    "post",
    () => {
      toast("Error uploading file");
    },
    "",
    pc
  );
};
export const addPlaylistFromFileMac = (form, cb, pc) => {
  request(
    CONFIG.API + "/parse_playlist_by_mac",
    form,
    cb,
    "post",
    () => {
      toast("Error uploading file");
    },
    "",
    pc
  );
};

export const resetPassword = (email, cb, cberror) => {
  request(CONFIG.API + "/reset_password", { email }, cb, "post", cberror);
};

export const confirmResetPassword = (code, email, password, cb, cberror) => {
  request(
    CONFIG.API + "/confirm_reset_password",
    { code, email, password },
    cb,
    "post",
    cberror
  );
};

export const recaptcha = (body, cb) => {
  request(CONFIG.RESSELER_API + "/resseler/recaptcha", body, cb, "post");
};


export const get_publishing_key = (cb) => {
  request(CONFIG.API_STRIPE + "/publishing_key", {}, cb, "get");
};

export const get_client_secret = (type, cb) => {
  request(CONFIG.API_STRIPE + `/secret?type=${type}`, {}, cb, "post");
};

const initialState = {
  isOpen: false,
};

export default function (state = initialState, { type }) {
  switch (type) {
    case "SHOW_SELECT_WRRAPER":
      return {
        ...state,
        isOpen: true,
      };

    case "HIDE_SELECT_WRRAPER":
      return {
        ...state,
        isOpen: false,
      };
    default:
      return state;
  }
}

import React, { Component } from 'react';
import './markup.css'
class TermsOfUse extends Component {

    render () {
        return (
            <div >
                <h1>TERMS OF USE</h1>
                <div style={ {
                    fontSize: "20px"
                } }>
                    The application <span>NordicIPTV</span> MediaPlayer is delivered without any content. <br />
                    In addition, no content is provided from the application even after activating it on our website. <br />
                    The information displayed at the beginning includes the <b>MAC</b> address as well as the website so that clients can activate the application once for life.
                    The <span>NordicIPTV</span> MediaPlayer app has a free 5-days trial period once installed on your smart TV to test the features of the app. The MAC address and the URL are essential for the proper functioning of the application.
                    <span>NordicIPTV</span> MediaPlayer does not provide any content or subscription.
                    The content and subscriptions provided under the <span>NordicIPTV</span> MediaPlayer app name are frauds. <span>NordicIPTV</span> MediaPlayer disclaims any responsibility for the use of fraudulent content.
                    The application <span>NordicIPTV</span> MediaPlayer or it is entitled can not be held as responsible for the quality or interruptions of the content used on <span>NordicIPTV</span> MediaPlayer,
                    <span>NordicIPTV</span> MediaPlayer only provides a media player.
                    The application <span>NordicIPTV</span> MediaPlayer does not provide any advertising for content providers or subscriptions and reserves the right to sue anyone who uses our name or logo for advertising purposes.
                    Supported <span>NordicIPTV</span> MediaPlayer Devices can be activated after one time purchace of <b>110MAD</b> (<b>≈10.10 Euros</b>) once for lifetime
                    <hr />
                    <p className={ "alert alert-danger" }>
                        After accepting the above conditions, no refund will be granted under any circumstances.<br />
                        Note: If you are using a stolen card, your mac will be banned and its IP address will be reported.</p>
                    <hr />
                    App Name <span>NordicIPTV</span> & <span>NordicIPTV</span> are owned and operated by NordicIPTV which reserves the right to restrict access to the app change its pricing and modify the terms of use at any time without the consent of its user. It is the user’s responsibility to stay up to date with the terms.
                </div>
            </div>
        );
    }
}

export default TermsOfUse;
import React, { Component } from "react";
import { confirmLoginCode, loginByEmail, loginByMac } from "../server";
import SimpleReactValidator from "simple-react-validator";
import PopUp from "./PopUp/PopUp";
import Loading from "./Loading";
import { connect } from "react-redux";
import { validateToken } from "../server"
import BgImage from '../img/iptv.jpeg';

class Login extends Component {
  constructor (props) {
    super(props);
    this.state = {
      mac: "",
      code: "",
      email: "",
      password: "",
      show: false,
      isLoading: false,
      validEmail: true
    };

    this.validator = new SimpleReactValidator();
    this.loginValidator = new SimpleReactValidator();
    this.onClose = this.onClose.bind(this);
  }
  componentDidMount() {
    validateToken((data) => {
      if (!data.error) {
        window.location.href = "#/account/devices";

      }

    }, () => {
      window.location.href = "#/login"
    })
  }

  hideForceLoading() {

    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 2000);

  }

  onClose() {
    this.setState({
      show: false,
    });
  }
  formValid(validator, filedName) {
    if (validator.fieldValid(filedName)) {
      return true;
    } else {
      validator.showMessages();
      this.forceUpdate();
      return false;
    }
  }

  login(e) {
    e.preventDefault();
    if (this.formValid(this.validator, "mac")) {

      this.setState({ isLoading: true });

      loginByMac(this.state.mac, (data) => {

        this.setState({
          show: true,
          isLoading: false,
        });

        let audio = document.getElementById("popup");

        audio.play();

      });

      this.hideForceLoading();

    }

  }

  loginByUsername() {

    if (this.formValid(this.loginValidator, "email") && this.formValid(this.loginValidator, "password")) {

      this.setState({ isLoading: true });

      loginByEmail(this.state.email, this.state.password, (data) => {
        this.setState({ isLoading: false });
        window.localStorage.setItem("auth", "user");
        window.localStorage.setItem("token", data.message);
        window.localStorage.setItem("email", this.state.email);
        window.location.href = "#/account/devices";

      });

      this.hideForceLoading();

    }

  }

  confirmCode() {
    if (this.formValid(this.validator, "code")) {
      this.setState({ isLoading: true });
      confirmLoginCode(
        {
          mac: this.state.mac,
          code: this.state.code,
        },
        (data) => {
          this.setState({
            isLoading: false,
          });
          window.localStorage.setItem("auth", "mac");
          window.localStorage.setItem("email", this.state.email);
          localStorage.setItem("token", data.message);
          window.location.href = "#/account/devices";
        }
      );
      this.hideForceLoading();
    }
  }

  render() {
    let loading = "";
    if (this.state.isLoading || this.props.translations.isLoading) {
      loading = <Loading />;
    }

    const {
      login_by_mac,
      login,
      login_by_your_mac,
      enter_mac,
      login_email_pass,
      enter_r_pass,
      forget_pass,
      login_r_acc,
      your_acc_will_give_vod,
      enter_r_add,
      enter_your_code,
    } = this.props.translations.state;
    return (
      <div className={"row"}>
        {loading}
        <PopUp
          onClose={this.onClose}
          body={
            <div>
              <h1>Enter code</h1>
              <p>
                {" "}
                {
                  this.props.translations.state[
                  "the code sent to your tv device"
                  ]
                }
              </p>
              <div className={"form-group"}>
                <label htmlFor="username">{enter_your_code}</label>
                <span className={"error-message"}>
                  {this.validator.message("code", this.state.code, "required")}
                </span>
                <input
                  value={this.state.code}
                  onChange={(event) => {
                    this.setState({ code: event.target.value });
                  }}
                  type="text"
                  id="code"
                  className={"form-control custom"}
                />
              </div>
              <div className={"form-group"}>
                <div className={"gravity-right"}>
                  <button
                    onClick={() => this.confirmCode()}
                    className={"btn btn-success"}
                  >
                    {this.props.translations.state["confirm code"]}
                  </button>
                </div>
              </div>
            </div>
          }
          show={this.state.show}
        />
        <div className={"col-lg-4 left-block"}>
          <h3 className={"heading"}>{login_by_your_mac}</h3>
          <form className={"form-group"} onSubmit={(event) => this.login(event)}>
            <label htmlFor="username">{enter_mac}</label>
            <span className={"error-message"}>
              {this.validator.message("mac", this.state.mac, "required")}
            </span>
            <input
              onChange={(event) => {
                this.setState({ mac: event.target.value });
              }}
              value={this.state.mac}
              type="text"
              id={"username"}
              className={"form-control custom"}
            />
            <div className={"gravity-right"}>
              <button
                className={"btn btn-success"}
              >
                {login_by_mac}
              </button>
            </div>
          </form>
          <div className={"form-group"}>

          </div>
          <hr />
          <h3 className={"heading"}>{login_email_pass}</h3>
          <div className={"form-group"}>
            <label htmlFor="username">{enter_r_add}</label>
            <span className={"error-message"}>
              {this.loginValidator.message(
                "email",
                this.state.email,
                "required|email"
              )}
              {!this.state.validEmail && this.state.email !== "" && "please enter valid email address"}
            </span>
            <input
              onChange={(event) => {

                let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
                if (event.target.value.match(mailformat)) {
                  this.setState({ validEmail: true });

                } else {
                  this.setState({ validEmail: false });
                }

                this.setState({ email: event.target.value });

              }}
              value={this.state.email}
              type="text"
              id={"username"}
              className={"form-control custom"}
            />
          </div>
          <form
            className={"form-group"}
            onSubmit={(e) => {
              e.preventDefault();
              this.loginByUsername();
            }}
          >
            <label htmlFor="password">{enter_r_pass}</label>
            <span className={"error-message"}>
              {this.loginValidator.message(
                "password",
                this.state.password,
                "required"
              )}
            </span>
            <input
              onChange={(event) => {
                this.setState({ password: event.target.value });
              }}
              value={this.state.password}
              type="password"
              id={"password"}
              className={"form-control custom"}
            />
            <div className={"form-group login_group"}>
              <a className={"bottom-link"} href="#/reset">
                {forget_pass}
              </a>
              <div className={"gravity-right"}>

                <button
                  // onClick={() => this.loginByUsername()}
                  className={"btn btn-success"}
                >
                  {login}
                </button>
              </div>
            </div>
          </form>
        </div>
        <div style={{ backgroundImage: "url('" + BgImage + "')", backgroundSize: "cover" }} className={"col-lg-8 aside-block"}>
          <div class="bg-mask"></div>
          <div className="content-block">
            <h1>{login_r_acc}</h1>
            <p>{your_acc_will_give_vod}</p>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    translations: state.translations,
  };
};

export default connect(mapStateToProps)(Login);

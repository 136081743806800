import React,{Component} from 'react';
class FAIL extends  Component{

    render() {
        return (
            <div style={{textAlign:"center"}}>
                <h1>FAILED TO CREATE PAYMENT</h1>
            </div>
        );
    }
}

export default FAIL;
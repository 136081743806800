import React, { Component } from 'react';
import { toast, ToastContainer } from "react-toastify";
import { confirmResetPassword, resetPassword } from "../server";
import SimpleReactValidator from "simple-react-validator";
import CONFIG from '../Config';
class Reset extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            code: "",
            new_password: "",
            code_sent: false,
            validEmail: true

        }

        this.validator = new SimpleReactValidator();

    }

    componentDidMount() {
    }

    formValid(validator, filedName) {
        if (validator.fieldValid(filedName)) {
            return true;
        } else {
            validator.showMessages();
            this.forceUpdate();
            return false;
        }
    }
    hideForceLoading() {
        setTimeout(() => {
            this.setState({ isLoading: false })
        }, 2000);

    }
    resetPassword() {
        resetPassword(this.state.email, (data) => {
            this.setState({ code_sent: true })
        }, (err) => {
            if (this.validator.errorMessages) {

                this.validator.message = err
                this.forceUpdate()
                this.validator.message = ""

            }
        })

    }

    confirmCodeAndReset() {
        confirmResetPassword(this.state.code, this.state.email, this.state.password, (data) => {
            toast.success("Password reset done");
            setTimeout(() => {
                window.location.href = CONFIG.LOGIN;
            }, 2000)
        }, (err) => {
            toast.error(err);

        })
    }

    render() {
        return (
            <div className={"row"}>
                <ToastContainer />
                <div className={"col-lg-6 left-block"}>
                    <div className={!this.state.code_sent ? "form-group" : "hid-reset-field"}>
                        <label htmlFor="email">Enter your email address</label>
                        <span className={"error-message"}>
                            {
                                this.state.email !== "" ? this.validator.message : ""
                            }
                            {!this.state.validEmail && this.state.email !== "" && "please enter valid email address"}
                        </span>
                        <input type="text" onInput={(event) => {
                            let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
                            if (event.target.value.match(mailformat)) {
                                this.setState({ validEmail: true });

                            } else {
                                this.setState({ validEmail: false });
                            }

                            this.setState({ email: event.target.value });
                            // this.setState({ email: event.target.value })
                        }} value={this.state.email} id={"email"} className={"form-control"} />
                    </div>
                    <div className={!this.state.code_sent ? "form-group" : "hid-reset-field"}>
                        <div className={"gravity-right"}>
                            <button onClick={() => this.resetPassword()} className={"btn btn-success"}>Get confirmation code</button>
                        </div>
                    </div>

                    {this.state.code_sent ?
                        <div>
                            <h4>Please check you email (<span>{this.state.email}</span>). We have sent confirmation code</h4>
                            <div className={"form-group"}>
                                <label >Enter confirm code from email</label>
                                <input className={"form-control"} type="number" value={this.state.code} onInput={(event) => {
                                    this.setState({ code: event.target.value })
                                }} />
                            </div>
                            <div className={"form-group"}>
                                <label >Enter new password</label>
                                <input className={"form-control"} type="password" value={this.state.password} onInput={(event) => {
                                    this.setState({ password: event.target.value })
                                }} />
                            </div>
                            <div className={"gravity-right"}>
                                <button onClick={() => this.confirmCodeAndReset()} className={"btn btn-success"}>Reset password</button>
                            </div>
                        </div>
                        :

                        ""
                    }
                    <a className={"bottom-link"} href="#/login">Login to your account</a>
                </div>
                <div className={"col-lg-6 aside-block"}>
                    <h1>Reset your password</h1>
                    <p className={"steps"}>Enter your email address we will send you a new password.</p>
                </div>
            </div>
        )
    }
}

export default Reset;
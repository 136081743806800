import React, { Component } from 'react';
import { Router, Route, hashHistory } from 'react-router';
import { syncHistoryWithStore, routerReducer } from 'react-router-redux'

/****
*  LOAD COMPONENTS
* */
import Body from './components/Body.js';
import Login from './components/Login';
import erroraudio from './components/PopUp/error.mp3';
import successaudio from './components/PopUp/openpopup.mp3';


import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from 'redux-devtools-extension';
import reducer from './reducers/index';
import thunk from 'redux-thunk';
import { request } from './server.js';
import CONFIG from "./Config";
import { ToastContainer } from "react-toastify";
import Registration from "./components/Registration";
import Reset from "./components/Reset";
import Help from "./components/Help";
import Payment from "./components/Payment";
import Devices from "./components/Devices";
import NewPlaylist from "./components/NewPlaylist";
import NewPlaylistMac from "./components/NewPlaylistMac";

import ManagePlatlists from "./components/ManagePlatlists";
import Logout from "./components/Logout";
import Start from "./components/Start";
import AccountBody from "./components/AccountBody";
import Home from "./components/Home";
import FAIL from "./components/FAIL";
import OK from "./components/OK";
import AutoAdd from "./components/AutoAdd";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsOfUse from "./components/TermsOfUse";
import TermsOfSales from "./components/TermsOfSales";
import Resseller from "./components/Resseller";
import EpgList from './components/EpgList.js';

const store = createStore(reducer, composeWithDevTools(applyMiddleware(thunk)));
const history = syncHistoryWithStore(hashHistory, store);

class App extends Component {
    constructor (props) {
        super(props);
    }

    updatedRouter () {
        window.scrollTo(0, 0);
    }

    render () {

        return (
            <>
                <ToastContainer />
                <audio id={ "erroraudio" } src={ erroraudio } style={ { display: "none" } } />
                <audio id={ "popup" } src={ successaudio } style={ { display: "none" } } />
                <Provider store={ store }>
                    <Router onUpdate={ () => this.updatedRouter() } history={ history }>
                        <Route path={ '/' } components={ Start } />

                        <Route path={ '/autologin' } components={ AutoAdd } />

                        <Route path={ '/' } component={ Body }>
                            <Route path={ '/login' } component={ Login } />
                            <Route path={ '/privacy' } component={ PrivacyPolicy } />
                            <Route path={ '/termsofuse' } component={ TermsOfUse } />
                            <Route path={ '/termsofsales' } component={ TermsOfSales } />
                            <Route path={ '/playlist_by_mac' } component={ NewPlaylistMac } />
                            <Route path={ '/ok' } component={ OK } />
                            <Route path={ '/fail' } component={ FAIL } />
                            <Route path={ '/registration' } component={ Registration } />
                            <Route path={ '/reseller' } component={ Resseller } />
                            <Route path={ '/reset' } component={ Reset } />
                            <Route path={ '/help' } component={ Help } />
                            <Route path={ '/payment' } component={ Payment } />
                        </Route>

                        <Route path={ '/account' } component={ AccountBody }>
                            <Route path={ 'devices' } component={ Devices } />
                            <Route path={ "/epg" } component={ EpgList } />
                            <Route path={ 'addpllaylist' } component={ NewPlaylist } />
                            <Route path={ 'manage' } component={ ManagePlatlists } />
                            <Route path={ 'logout' } component={ Logout } />
                        </Route>
                    </Router>
                </Provider>
            </>
        );
    }
}

export default App;

import React, { Component } from "react";
import SimpleReactValidator from "simple-react-validator";
import { addNewPlaylistMac, addPlaylistFromFileMac } from "../server";
import Loading from "./Loading";
import { toast, ToastContainer } from "react-toastify";
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";
import { connect } from "react-redux";
import BgImage from '../img/iptv.jpeg';

class NewPlaylist extends Component {
  constructor (props) {
    super(props);
    this.state = {
      name: "",
      url: "",
      mac: "",
      isLoading: false,
      authType: "user",
      progress: 0,
      type: "url",
      playlistType: "playlist",
      password: "",
      host: ""
    };

    this.validator = new SimpleReactValidator();
    this.mac = this.props.location.mac
  }

  componentDidMount() {
    let auth = this.props.location.query.auth;
    this.setState({ type: this.type });
    if (auth) {
      window.localStorage.setItem("auth", auth);
      this.setState({
        authType: auth,
      });
    }
    if (this.device) {
      this.setState({ name: "playlist for " + this.device });
    }

  }

  formValid(validator, filedName) {
    if (validator.fieldValid(filedName)) {
      return true;
    } else {
      validator.showMessages();
      this.forceUpdate();
      return false;
    }
  }

  hideForceLoading() {
    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 2000);
  }
  addPlaylist() {
    let elem = document.getElementById("file");
    let file = elem.files[0];
    if (file) {
      if (!this.formValid(this.validator, "name")) {
        toast.error("name of the playlist required");
        return;
      }
      let formData = new FormData();
      let type = file.name.split(".")[file.name.split(".").length - 1];
      if (type === "m3u8" || type === "m3u") {
        formData.append("file", file);
        formData.append("isFromFile", 1);
        formData.append("name", this.state.name);
        formData.append("mac", this.state.mac);
        addPlaylistFromFileMac(
          formData,
          () => {
            this.setState({ isLoading: false });
          },
          (data) => {
            this.setState({ progress: data });
          }
        );
      } else {
        toast.error("Invalid file. Use only (m3u,m3u8) files");
      }
    } else {

      const body = {
        mac: this.state.mac,
        name: this.state.name,
      }

      if (this.state.playlistType === "playlist") {
        body.url = this.state.url
      } else {
        body.url = `${this.state.host}get.php?username="${body.name}"&password="${body.password}"&type=m3u_plus&output=m3u8"`
      }

      if (
        this.formValid(this.validator, "url") &&
        this.formValid(this.validator, "name")
      ) {
        this.setState({ isLoading: true });

        addNewPlaylistMac(
          body,
          (data) => {
            setTimeout(() => {
              this.setState({ isLoading: false });
            }, 1000);
          }
        );
        this.hideForceLoading();
      }
    }
  }

  setFile(e) {
  }

  render() {
    let loading = "";
    if (this.state.isLoading || this.props.translations.isLoading) {
      loading = <Loading />;
    }

    const {
      your_device_mac,
      enter_tv_mac_add,
      enter_playlist_name,
      enter_playlist_url,
      or,
      choose_playlist_file,
      add_new_playlist,
      add_playlist_from_url,
    } = this.props.translations.state;

    return (
      <form
        className={"row"}
        onSubmit={(e) => {
          e.preventDefault();
          this.addPlaylist()
        }}
      >
        <ToastContainer />
        {loading}
        <div className={"col-lg-4 left-block"}>
          <div className="tab_contsiner">
            <div className={this.state.playlistType === "playlist" ? "tab_buttons_active" : "tab_buttons"}
              onClick={() => {

                this.setState({
                  playlistType: "playlist"
                })
              }}>
              Playlist
            </div>
            <div className={this.state.playlistType === "xtream" ? "tab_buttons_active" : "tab_buttons"}
              onClick={() => {

                this.setState({
                  playlistType: "xtream"
                })
              }}>
              Xtream account
            </div>
          </div>
          <h1 className={"heading"}>
            {your_device_mac} {this.mac}
          </h1>
          {
            this.state.playlistType === "playlist" &&
            <>
              <div className={"form-group"}>
                <label htmlFor="playlistmac">{enter_tv_mac_add}</label>
                <span className={"error-message"}>
                  {this.validator.message("mac", this.state.mac, "required")}
                </span>
                <input
                  value={this.state.mac}
                  onChange={(event) => {
                    this.setState({ mac: event.target.value });
                  }}
                  type="text"
                  id={"playlistmac"}
                  className={"form-control"}
                />
              </div>
              <div className={"form-group"}>
                <label htmlFor="playlistname">{enter_playlist_name}</label>
                <span className={"error-message"}>
                  {this.validator.message("name", this.state.name, "required")}
                </span>
                <input
                  value={this.state.name}
                  onChange={(event) => {
                    this.setState({ name: event.target.value });
                  }}
                  type="text"
                  id={"playlistname"}
                  className={"form-control"}
                />
              </div>
              <div className={"form-group"}>
                <label htmlFor="playlisturl">{enter_playlist_url}</label>
                <span className={"error-message"}>
                  {this.validator.message("url", this.state.url, "required")}
                </span>
                <input
                  value={this.state.url}
                  onChange={(event) => {
                    this.setState({ url: event.target.value });
                  }}
                  type="text"
                  id={"playlisturl"}
                  className={"form-control"}
                />
              </div>
            </>
          }
          {
            this.state.playlistType == "xtream" &&

            <>

              <div className={"form-group"}>
                <label htmlFor="playlistmac">Enter Xtream host</label>
                <span className={"error-message"}>
                  {this.validator.message("mac", this.state.host, "required")}
                </span>
                <input
                  value={this.state.host}
                  onChange={(event) => {
                    this.setState({ host: event.target.value });
                  }}
                  type="text"
                  id={"playlistmac"}
                  className={"form-control"}
                />
              </div>
              <div className={"form-group"}>
                <label htmlFor="playlistmac">Enter Xtream username</label>
                <span className={"error-message"}>
                  {this.validator.message("mac", this.state.name, "required")}
                </span>
                <input
                  value={this.state.name}
                  onChange={(event) => {
                    this.setState({ name: event.target.value });
                  }}
                  type="text"
                  id={"playlistmac"}
                  className={"form-control"}
                />
              </div>
              <div className={"form-group"}>
                <label htmlFor="playlistmac">Enter Xtream password</label>
                <span className={"error-message"}>
                  {this.validator.message("mac", this.state.password, "required")}
                </span>
                <input
                  value={this.state.password}
                  onChange={(event) => {
                    this.setState({ password: event.target.value });
                  }}
                  type="password"
                  id={"playlistmac"}
                  className={"form-control"}
                />
              </div>
            </>


          }

          <p className={"separator"}> {or} </p>

          <div className={"form-group"}>
            <label htmlFor="file">{choose_playlist_file}</label>
            <input type="file" id={"file"} />
          </div>

          <div className={"bottom-form-block"}>
            {this.state.progress > 0 ? (
              <Progress
                type="circle"
                percent={this.state.progress}
                theme={{
                  active: {
                    symbol: this.state.progress + "%",
                    trailColor: "white",
                    color: "gray",
                  },
                  success: {
                    symbol: this.state.progress + "%",
                    trailColor: "lime",
                    color: "green",
                  },
                }}
              />
            ) : (
              ""
            )}

            <div className={"form-group"}>
              <button
                style={{
                  marginTop: "12px",
                }}
                className={"btn btn-success"}
              >
                {add_new_playlist}
              </button>
            </div>
          </div>
        </div>
        <div style={{ backgroundImage: "url('" + BgImage + "')", backgroundSize: "cover" }} className={"col-lg-8 aside-block"}>
          <div class="bg-mask"></div>
          <div className="content-block">
            <h1>{add_new_playlist}</h1>
            <p>{add_playlist_from_url}</p>
          </div>
        </div>
      </form >
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.isLoading,
    translations: state.translations,
  };
};

export default connect(mapStateToProps)(NewPlaylist);

import React, { Component } from "react";
import devices from "../data/devices.json";
import plicon from "../img/plicon.jpg";
import { connect } from "react-redux";

import {
  addDevice,
  confirmDeviceCode,
  getDevice,
  getDevices,
  removePlaylist,
  addNewPlaylist
} from "../server";
import LG from "../img/lg.png";
import Tizen from "../img/tizen.png";
import PopUp from "./PopUp/PopUp";
import SimpleReactValidator from "simple-react-validator";
import Loading from "./Loading";

class Devices extends Component {
  constructor (props) {
    super(props);
    this.state = {
      key: -1,
      devices: [],
      show: false,
      codeFetched: false,
      code: "",
      mac: "",
      isLoading: false,
      isMacAuth: false,
      fetched: false,
      playlist: null,
    };

    this.validator = new SimpleReactValidator();
    this.codeValidtor = new SimpleReactValidator();
    this.onClose = this.onClose.bind(this);
  }

  onClose () {
    this.setState({ show: false, codeFetched: false });
  }

  hideForceLoading () {
    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 2000);
  }
  findDevices () {
    let auth = window.localStorage.getItem("auth");
    this.setState({ isLoading: true });
    if (auth === "mac") {
      getDevice((data) => {
        this.state.devices = [];
        this.state.devices.push(data.message);
        this.setState({
          isMacAuth: true,
          devices: this.state.devices,
          playlist: data.message.playlist,
        });
      });
    } else {
      getDevices((data) => {
        this.setState({ devices: data.message }, () => {
          this.setState({ isLoading: false, fetched: true });
        });
      });
    }

    this.hideForceLoading();
  }
  componentDidMount () {
    this.findDevices();
  }

  open (key) {
    if (key === this.state.key) {
      this.setState({ key: -1 });
    } else {
      this.setState({ key });
    }
  }
  openManager (id, deviceIndex, playlistIndex, name) {
    window.location.href =
      "#/account/manage?id=" + id + "+deviceIndex=" + deviceIndex + "&playlistIndex=" + playlistIndex + "&name=" + name;
    window.location.reload();
  }
  formValid (validator, filedName) {
    if (validator.fieldValid(filedName)) {
      return true;
    } else {
      validator.showMessages();
      this.forceUpdate();
      return false;
    }
  }

  removePlaylist (id) {
    this.setState({ isLoading: true });
    removePlaylist(id, (data) => {
      this.findDevices();
    });
    this.hideForceLoading();
  }
  addDevice () {
    if (this.formValid(this.validator, "mac")) {
      this.setState({ isLoading: true });
      addDevice(this.state.mac, (data) => {
        this.setState({
          isLoading: false,
          codeFetched: true,
        });
      });
      this.hideForceLoading();
    }
  }
  confirmCode() {
    if (
      this.formValid(this.validator, "mac") &&
      this.formValid(this.codeValidtor, "code")
    ) {
      this.setState({ isLoading: true });
      confirmDeviceCode({ code: this.state.code, mac: this.state.mac }, () => {
        this.findDevices();
        this.onClose();
      });
      this.hideForceLoading();
    }
  }

  playlistAction(e, index) {
    e.preventDefault();
    if(this.state.playlist) {
      console.log('ok')
      // if (this.formValid(this.validator, "playlist")) {

        this.setState({ isLoading: true });

        addNewPlaylist({ mac: this.mac, name: "playlist for tizen", url: this.state.playlist }, (data) => {

            this.setState({ isLoading: false });

            // setTimeout(() => {
            //     window.location.reload();
            // }, 1500);

        });

        this.hideForceLoading();
    // }

    } else {
      window.location.href =
      "#/account/addpllaylist?type=url&mac=" +
      index.mac +
      "&name=" +
      index.model +
      "&id=" +
      index.id;
    window.location.reload();
    }
  }

  render() {
    let loading = "";
    let addBtn = "";
    if (this.state.isLoading || this.props.translations.isLoading)
      loading = <Loading />;
    if (!this.state.isMacAuth && this.state.fetched) {
      addBtn = (
        <div style={ { textAlign: "right" } } className={ "col-md-6" }>
          <button
            onClick={ () => {
              this.setState({ show: true });
            } }
            className={ "btn btn-success" }
          >
            Add new device
          </button>
        </div>
      );
    }

    const {
      add_new_playlist,
      hit_add_device,
      name,
      playlists,
      enter_mac,
      devices,
    } = this.props.translations.state;
    return (
      <div>
        { loading }
        <div className={ "row" }>
          <div className={ "col-md-6" }>
            <h1 className={ "heading" }>{ devices }</h1>
          </div>
          { addBtn }
        </div>
        <PopUp
          onClose={ this.onClose }
          body={
            <div>
              {
                !this.state.codeFetched ?
                  <div className={ "popup_wrapper" }>
                    <h2>Enter device mac and activate it</h2>
                    <p>
                      Make sure that you have already downloaded application to you
                      device
                    </p>
                    <form
                      className={ "row" }
                      onSubmit={ (e) => {
                        e.preventDefault();
                        this.addDevice();
                      } }
                    >
                      <div className={ "form-group device_formgroup" }>
                        <label htmlFor="username">{ enter_mac }</label>

                        <span className={ "error-message" }>
                          { this.validator.message(
                            "mac",
                            this.state.mac,
                            "required"
                          ) }
                        </span>
                        <input
                          value={ this.state.mac }
                          onChange={ (event) => {
                            this.setState({ mac: event.target.value });
                          } }
                          type="text"
                          id="code"
                          className={ "form-control" }
                        />
                        <div className={ "gravity-right" }>
                          <button
                            className={ "btn btn-success rightInputBtn" }
                          >
                            Add device
                          </button>
                        </div>
                      </div>

                    </form>
                  </div> :
                  <div className={ "popup_wrapper" } >
                    <h2>Enter device mac and activate it</h2>
                    <p>
                      Make sure that you have already downloaded application to you
                      device
                    </p>
                    <form
                      className={ "row" }
                      onSubmit={ (e) => {
                        e.preventDefault();
                        this.confirmCode();
                      } }
                    >
                      <div className={ "form-group device_formgroup" }>
                        <label htmlFor="username">
                          { this.props.translations.state["enter your code"] }
                        </label>
                        <p className={ "alert alert-primary" }>
                          {
                            this.props.translations.state[
                            "the code sent to your tv device"
                            ]
                          }
                        </p>
                        <span className={ "error-message" }>
                          { this.validator.message("mac", this.state.mac, "required") }
                        </span>
                        <input
                          value={ this.state.code }
                          onChange={ (event) => {
                            this.setState({ code: event.target.value });
                          } }
                          type="text"
                          id="code"
                          className={ "form-control" }
                        />
                        <div className={ "gravity-right" }>
                          <button
                            className={ "btn btn-success rightInputBtn" }
                          >
                            { this.props.translations.state["confirm code"] }
                          </button>
                        </div>
                      </div>

                    </form>
                  </div>
              }

            </div>
          }
          show={ this.state.show }
        />
        {
          this.state.devices.length ? (
            this.state.devices.map((index, key) => {
              let block;
              let opener = (
                <i
                  onClick={ () => this.open(key) }
                  className={ "fa fa-arrow-down" }
                ></i>
              );
              if (this.state.key === key) {
                block = (
                  <div className={"col-md-12 more-playlists"}>
                    <div className={"row"}>
                      {/* <div className={"col-md-6"}>
                        <h2>{playlists}</h2>
                      </div> */}
                      <div className={"col-md-12"}>
                        <div className={"gravity-right playlist-editable d-flex"}>
                        {
this.state.playlist ? (
                            <input className="form-control playlist-name__field" value={this.state.playlist} onChange={(event) => this.setState({playlist: event.target.value})} />) : null
}
                          <a
                            onClick={ (event) => this.playlistAction(event, index) }
                            style={ { color: "white", display: "inline-block" } }
                            className={ "btn btn-success add-edit__btn" }
                          >
                            {!this.state.playlist ? add_new_playlist : 'Save playlist'}
                          </a>
                        </div>
                      </div>
                    </div>
                    { index.playlists.map((pIndex, pKey) => {
                      return (
                        <div key={ pKey } className={ "row playlist-item" }>
                          <div className={ "col-md-2 col-sm-2" }>
                            <img style={ { width: "70px" } } src={ plicon } alt="" />
                          </div>
                          <div className={ "col-md-6 col-sm-6" }>
                            <p>
                              { name } : { pIndex.name }
                            </p>
                          </div>

                          <div className={ "col-md-4 col-sm-4 action-btns" }>
                            <i
                              style={ { color: "red" } }
                              onClick={ () => this.removePlaylist(pIndex.id) }
                              className={ "fa fa-trash" }
                            />
                          </div>
                        </div>
                      );
                    }) }
                  </div>
                );

                opener = (
                  <i
                    onClick={ () => this.open(key) }
                    className={ "fa fa-arrow-up" }
                  ></i>
                );
              }
              return (
                <div key={ key } className={ "row device-item" }>
                  <div className={ "col-md-3" }>
                    { index.model.toLowerCase() === "tizen" ? (
                      <img style={ { width: "50%" } } src={ Tizen } alt="" />
                    ) : (
                      <img style={ { width: "50%" } } src={ LG } alt="" />
                    ) }
                  </div>
                  <div className={ "col-md-6" }>
                    <p>Name : { index.model }</p>
                    <p>Mac : { index.mac }</p>
                  </div>
                  <div className={ "col-md-3 open-block" }>
                    { opener }
                    {/* {index.payed ?  opener : <a style={{
                                        position: "absolute",
                                        right: 0
                                    }} className={"btn btn-danger"} href={"#/payment?mac="+index.mac}>Activate your device</a>} */}
                  </div>

                  { block }
                </div>
              );
            })
          ) : (
            <div
              style={ { textAlign: "center", marginTop: "20px" } }
              className={ "device" }
            >
              <h2>No devices found</h2>
              <h4>{ hit_add_device }</h4>
            </div>
          )
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.isLoading,
    translations: state.translations,
  };
};

export default connect(mapStateToProps)(Devices);

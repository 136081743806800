import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export default ({
  languages,
  isOpen,
  toggleSelectVisibility,
  changeLanguageHandler,
  openOutsideBar,
}) => {
  // const [isOpen, setIsOpen] = useState(false);
  const [defaultSelected, setDefaultSelected] = useState();

  const outSideWrapper = useSelector((state) => state.global.isOpen);

  useEffect(() => {
    let activeLanguage;
    if (localStorage.getItem("language_id")) {
      activeLanguage = languages.find(
        (item) => item.id === +localStorage.getItem("language_id")
      );
    } else {
      activeLanguage = languages.find((item) => item.is_default);
    }
    setDefaultSelected(activeLanguage);
  }, []);

  return (
    <div className="custom-select_main">
      <div
        className="selected_language"
        onClick={ () => {
          toggleSelectVisibility(!isOpen);
          // openOutsideBar();
        } }
      >
        { defaultSelected && (
          <>
            <img src={ defaultSelected.icon } alt="" />
            <p>{ defaultSelected.name }</p>
          </>
        ) }
      </div>
      <div className={ `list_language ${isOpen ? "opened" : ""}` }>
        { languages.map((item, index) => {
          return (
            <div
              key={ index }
              className={ `item_language` }
              onClick={ () => {
                toggleSelectVisibility(!isOpen);
                setDefaultSelected(item);
                changeLanguageHandler(item.id);
              } }
            >
              <img src={ item.icon } alt="" />
              <p>{ item.name }</p>
            </div>
          );
        }) }
      </div>
    </div>
  );
};

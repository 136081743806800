import React from "react";

export default ({ data, remove }) => {
    const removeHandler = (id) => {
        remove(id);
    };
    return (
        <ul className="item-list_main list-group">
            {data.map((item, idx) => {
                return (
                    <li
                        key={idx}
                        className="list-group-item  list-group-item-action item-list_main-title"
                    >
                        <p className="m-0 text-truncate" style={{ maxWidth: "60%" }}>
                            {item.url}
                        </p>
                        <p className="m-0 btn" onClick={() => removeHandler(item.id)}>
                            <i className="fa fa-trash text-danger"></i>
                        </p>
                    </li>
                );
            })}
        </ul>
    );
};

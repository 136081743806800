import React, { Component } from "react";
import CONFIG from "../Config";
import Loading from "./Loading";

import { connect } from "react-redux";

class Help extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    };
  }

  render() {
    let loading = "";
    const { login_r_acc, activation_link, for_more_help } =
      this.props.translations.state;

    if (this.state.isLoading || this.props.translations.isLoading) {
      loading = <Loading />;
    }
    return (
      <div className={"help"}>
        {loading}
        <h1 className={"heading"}>{login_r_acc}</h1>
        <p>
          {this.props.translations.state["download on your tv"]}{" "}
          <span className="bold-app-name">{CONFIG.APP_NAME}</span>{" "}
          {
            this.props.translations.state[
              "app and run it. Follow activation link for activating your tv device"
            ]
          }
        </p>
        <a className={"btn btn-success"} href={CONFIG.ACTIVATION}>
          {activation_link}
        </a>
        <hr />

        <h1>
          {for_more_help} :{" "}
          <a href={`mailto:${CONFIG.SUPPORT_EMAIL}`}>{CONFIG.SUPPORT_EMAIL}</a>
        </h1>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    isLoading: state.isLoading,
    translations: state.translations,
  };
};

export default connect(mapStateToProps)(Help);

import React, { Component } from 'react';
import CONFIG from '../Config'
class Footer extends Component {
    constructor (props) {
        super(props);
    }

    render() {
        return (
            <footer>
                <p>NipTV Copyright 2020-{new Date().getFullYear()} &copy;</p>
                <ul style={{ listStyle: "none" }}>
                    <li>
                        <a style={{ color: "white" }} href="#/reseller">
                            Become a reseller
                        </a>
                    </li>
                    <li>
                        <a style={{ color: "white" }} href={CONFIG.RESELLER_PANEL}>
                            Reseller Panel
                        </a>
                    </li>
                    <li>
                        <a style={{ color: "white" }} href="mailto:support@niptv.eu">Contact for support</a>
                    </li>
                </ul>
            </footer >
        )
    }
}

export default Footer;
import React from 'react';
class PopUp extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (<div>
            <div style={{ display: this.props.show === true ? "block" : "none" }} className={"popup"} />
            <div style={{ display: this.props.show === true ? "block" : "none" }} className={"popup-content fadeIn"}>
                <div className={"close"} onClick={this.props.onClose}>
                </div>
                {this.props.body}
            </div>
        </div>)
    }
}

export default PopUp;